import React, { useState, useEffect } from "react";
import styles from './TopBanner.module.scss';

const TopBanner = ({ data }) => {
  return (
    <div className={styles.topbanner}>
      <div className={styles.topbannerContainer}>
        <div className={styles.content} dangerouslySetInnerHTML={ { __html: data.text } }></div>
      </div>
    </div>
  );
}

export default TopBanner;