import { useEffect, useState } from "react"

export default function useWindowResize(breakpointValue) {

    const [isBelowBreakValue, setIsBelowBreakValue] = useState(false)

    useEffect(() => {
        if (breakpointValue) {
            const handleResize = () => {
                if (window) {
                    setIsBelowBreakValue(window.innerWidth < breakpointValue)
                }
            }

            handleResize()
            window.addEventListener('resize', handleResize)
            
            return () => { window.removeEventListener('resize', handleResize) }
        }
    }, [])

    return isBelowBreakValue
}
