import React, { useEffect, useState } from "react";
import useWindowResize from "../../hooks/useWindowResize";
import ArrowRight from "../../public/assets/icons/arrowRight.svg";


const base = {
  pointerEvents: "none",
  userSelect: "none",
  display: "block",
  position: "fixed",
  top: "50%",
  left: "50%",
  zIndex: "99999999999",
  transform: "translate(-50%, -50%)",
  borderRadius: "100%",
};
const Cursor = ({
  pulse = false,
  size = 23,
  hollowSize = 23,
  opacity = 1,
  color = '#b1a38a', // gold
  easing = "cubic-bezier(0.18, 0.89, 0.32, 1.28)",
  duration = 0.4,
  custom = false,
  ...rest
}) => {
  const [moved, setMoved] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [hollow, setHollow] = useState(false);
  const [hollowSwiper, setHollowSwiper] = useState(false);
  const [dynamicSize, setDynamicSize] = useState(0);
  const [arrowAnimation, setArrowAnimation] = useState(false);
  const isMobile = useWindowResize(1200)

  useEffect(() => {
    let isMounted = true;
    let moving = false;

    document.addEventListener('scroll', () => {
      if(!isMounted) return;
      if (isMobile) {
        return;
      }
      if (moving) return;
      setArrowAnimation(false);
      setHollow(true);
      setDynamicSize(size);
    })

    const mouseMoveHandler = (event) => {
      if(!isMounted) return;
      if (isMobile) {
        return;
      }
      if(!moved) {
        setTimeout(() => {
          setMoved(true);
        }, 500);
      }
      var f = () => {
        moving = true;

        var e = event;
        setX(e.clientX);
        setY(e.clientY);

        const els = [
          "A", "BUTTON", "INPUT", "SELECT", "SPAN", "DIV"
        ];

        let elm = e.target;

        do {
          let foundHollow = false;
          let foundArrows = false;
          if (els.indexOf(elm.nodeName) >= 0) {
            switch (elm.nodeName) {
              case "SPAN":
                if (elm.classList && [...elm.classList].indexOf('swiper-pagination-bullet') >= 0) {
                  foundHollow = true;
                }
                break;
              case "DIV":
                if (elm.classList && ([...elm.classList].indexOf('swiper-button-next') >= 0 || [...elm.classList].indexOf('swiper-button-prev') >= 0)) {
                  if (elm.classList && [...elm.classList].indexOf('swiper-button-disabled') < 0) {
                    foundHollow = true;
                    break;
                  }
                }
                if (elm.classList && [...elm.classList].indexOf('swiper') >= 0) {
                  foundArrows = true;
                }
                break;
              case "SVG":
                if (elm.classList || [...elm.classList].indexOf('fa-chevron-down') >= 0) {
                  foundHollow = true;
                }
                break;
              default:
                foundHollow = true;
                break;
            }
          }
          if (foundHollow) {
            setHollow(false);
            setHollowSwiper(false)
            setArrowAnimation(false);
            setDynamicSize(hollowSize);
            moving = false;
            return;
          }
          if (foundArrows) {
            setHollow(true);
            setHollowSwiper(true)
            setArrowAnimation(true);
            setDynamicSize(size);
            moving = false;
            return;
          }
          elm = elm.parentElement;
        }
        while (elm);

        setArrowAnimation(false);
        setHollow(true);
        setHollowSwiper(false)
        setDynamicSize(size);
        moving = false;
      };
      setTimeout(() => {
        requestAnimationFrame(f);
      });
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('click', mouseMoveHandler);
    return () => { 
      isMounted = false;
      // document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('click', mouseMoveHandler);
    };
  }, [isMobile]);

  const styles = {
    ...base,
    visibility: moved ? 'visible' : 'hidden',
    animation: pulse ? "pulse 2s infinite" : null,
    backgroundColor: hollow ? (hollowSwiper ? "#a0917a" : "transparent") : "#a0917a",
    border: hollow ? (arrowAnimation ? "" : "1px solid ") + color : null,
    opacity: opacity,
    width: hollowSwiper ? dynamicSize + "px" : dynamicSize/2 + "px",
    height: hollowSwiper ? dynamicSize + "px" : dynamicSize/2 + "px",
    top: y,
    left: x,
    transitionTimingFunction: easing,
    transitionDuration: duration + "s"
  };
  const leftStyle = {
    color: color, position: "absolute", left: "12px", top: "16px", transform: "rotate(180deg)"
  }
  const rightStyle = {
    color: color, position: "absolute", right: "12px", top: "18px"
  }
  const ArrowStaticLeftComponent = (
    <div style={leftStyle}>
       <ArrowRight />
    </div>
  );
  const ArrowStaticRightComponent = (
    <div style={rightStyle}>
     <ArrowRight />
    </div>
  );
  return !isMobile ? (
    <div id="cursor" {...rest} style={!custom ? styles : null}>
      {arrowAnimation ? ArrowStaticLeftComponent : ''}
      {arrowAnimation ? ArrowStaticRightComponent : ''}
    </div>
  ) : null;
};

export default React.memo(Cursor);
