import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Cursor from "../components/Cursor/Cursor";
import { ApplicationContextProvider } from "../context/ApplicationContext";
import useWindowResize from "../hooks/useWindowResize";
import Head from "next/head";
import App from "next/app";
import "../styles/main.scss";
import { useEffect } from "react";
import { setCookies, checkCookies, removeCookies } from "cookies-next";
import { fetchData } from "../fetcher";
import Popup from "../components/Popup/Popup";
import TopBanner from "../components/TopBanner/TopBanner";

config.autoAddCss = false;

function MyApp({ Component, pageProps, router, popup, topbanner }) {
  const isMobile = useWindowResize("lg");

  const headMeta =
    pageProps && pageProps.dataContent ? pageProps.dataContent.head_meta : null;
  const page = {
    head: headMeta || {
      seo: {
        title:
          "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
        description:
          "Are you looking for a luxury designer outlet in Italy? Welcome to The Mall Luxury Outlets, a unique shopping experience at excellent prices in Tuscany and Liguria.",
        image: "/assets/favicon/favicon.ico",
      },
      facebook: {
        title:
          "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
        description:
          "Are you looking for a luxury designer outlet in Italy? Welcome to The Mall Luxury Outlets, a unique shopping experience at excellent prices in Tuscany and Liguria.",
        image: "/assets/favicon/favicon.ico",
      },
      twitter: {
        title:
          "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
        description:
          "Are you looking for a luxury designer outlet in Italy? Welcome to The Mall Luxury Outlets, a unique shopping experience at excellent prices in Tuscany and Liguria.",
        image: "/assets/favicon/favicon.ico",
      },
    },
  };

  const baseUrl = `${process.env.NEXT_PUBLIC_FE_BASEURL}`;
  let pageUrl = baseUrl + router.asPath;
  const locale = router.query.locale;

  const alternates = headMeta?.alternates || [];

  const buildJsonLd = () => {
    if (alternates && alternates.length > 0) {
      if (page.slug == "home") {
        return {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name:
            page?.head?.seo?.title ||
            "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
          description:
            page?.head?.seo?.description ||
            "Are you looking for a luxury designer outlet in Italy? Welcome to The Mall Luxury Outlets, a unique shopping experience at excellent prices in Tuscany and Liguria.",
          url: baseUrl + "/" + locale,
          /*"potentialAction": {
            "@type": "SearchAction",
            "target": searchPageUrl,
            "query-input": "required name=search_term_string"
          }*/
        };
      } else {
        let localizedPage = alternates.filter(obj => {
          return obj.language_code === locale
        })
        if (localizedPage && localizedPage.length > 0) {
          localizedPage = localizedPage[0];
          let ret = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [],
          };
          if (pageProps.dataContent?.head?.breadcrumb && pageProps.dataContent?.head?.breadcrumb.currentPage) {
            let currIdx = 0;
            pageProps.dataContent?.head?.breadcrumb.path.map((item, index) => {
              if (item) {
                let breadcrumbUrl = item.url;
                ret.itemListElement.push({
                  "@type": "ListItem",
                  position: currIdx + 1,
                  name: item.title,
                  item: baseUrl + breadcrumbUrl,
                });
                currIdx++;
              }
            });
            ret.itemListElement.push({
              "@type": "ListItem",
              position: currIdx + 1,
              name:
                page?.head?.seo?.title ||
                "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
              item: baseUrl + localizedPage.localized_slug,
            });
          } else {
            localizedPage = localizedPage.localized_slug.split("/");
            let breadcrumbUrl = "";
            let currIdx = 0;
            localizedPage.map((item, index) => {
              if (item && item.trim().length > 0) {
                breadcrumbUrl += "/" + item;
                if (index != localizedPage.length - 1) {
                  ret.itemListElement.push({
                    "@type": "ListItem",
                    position: currIdx + 1,
                    /*name:
                      "The Mall – Italian Luxury Outlets",*/
                    item: baseUrl + breadcrumbUrl,
                  });
                } else {
                  ret.itemListElement.push({
                    "@type": "ListItem",
                    position: currIdx + 1,
                    name:
                      page?.head?.seo?.title ||
                      "The Mall – Italian Luxury Outlets – Designer Outlets in Florence and Sanremo",
                    item: baseUrl + breadcrumbUrl,
                  });
                }
                currIdx++;
              }
            });
          }

          return ret;
        }
      }
      return null;
    }
  };
  const jsonLd = buildJsonLd();

  // gestione passaggio login da un dominio all'altro
  useEffect(() => {
    if (!window.waitShow) {
      let cnt = 0;
      let ci = setInterval(() => {
        let $container = document.querySelector('#global-container');
        if ($container || cnt > 100) {
          clearInterval(ci);
        }
        if ($container) {
          $container.classList.add("shown");
        }
        cnt++;
      }, 100);
    }
    let params = [];
    const p = new URLSearchParams(window.location.search);

    for (const param of p) {
      params[param[0]] = param[1];
    }

    if (params.a_t) {
      init(params.a_t)
    }
  }, [])

  const init = async (token) => {
    const resName = await fetch(
      `${process.env.NEXT_PUBLIC_FE_COMMON}/customer?token=` +
      token
    );
    const namejs = await resName.json();

    const loyalty = await fetch(
      `${process.env.NEXT_PUBLIC_FE_COMMON}/customer_exist?email=` +
      namejs.response.email
    );
    const loyaltyjs = await loyalty.json()

    if (namejs && loyaltyjs) {
      if (checkCookies("myarea")) {
        removeCookies("myarea");
      }
      setCookies(
        "myarea",
        {
          access_token: token,
          loyalty: loyaltyjs.response.programKeys,
          first_name: namejs.response.first_name
        },
        {
          maxAge: 3600,
        }
      );
      router.push(window.location.pathname)
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{page.head.seo.title}</title>
        <meta name="description" content={page.head.seo.description} />
        <meta property="og:type" content="website" />

        <link rel="canonical" href={pageUrl} />
        {pageUrl && <meta property="og:url" content={pageUrl} />}

        <meta name="thumbnail" content="/assets/img/thumbnail.png" />
        <meta name="robots" content="index, follow"></meta>
        {
          alternates?.map((item, index) => {
            if (item.canonical) {
              return (
                /*<link href={item.localized_slug} hreflang={item.language_code} rel="canonical" key={`canonical-${index}`} />*/
                <link rel="alternate" hrefLang={item.language_code} href={baseUrl + item.localized_slug.replace("/home", "")} key={`can-${index}`} />
              );
            }
          })
        }
        {
          alternates?.map((item, index) => {
            if (item.canonical) {
              return (
                <link rel="alternate" hrefLang="x-default" href={item.localized_slug.indexOf("/home") != -1 || item.localized_slug == "/" + locale ? baseUrl : baseUrl + item.localized_slug.replace("/home", "")} key={`hreflang-${index}`} />
              );
            } else {
              return (
                <link rel="alternate" hrefLang={item.language_code} href={baseUrl + item.localized_slug.replace("/home", "")} key={`hreflang-${index}`} />
              );
            }
          })
        }
        <meta
          property="og:title"
          key="ogtitle"
          content={page.head.facebook.title}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1175" />
        <meta property="og:image:height" content="570" />
        <meta
          property="og:description"
          key="ogdesc"
          content={page.head.facebook.description}
        />
        <meta
          property="og:image"
          key="ogimage"
          content={
            page?.head?.facebook?.image ? page.head.facebook.image : "/assets/img/thumbnail.png"
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={
            page?.head?.twitter?.profile ? page?.head?.twitter?.profile : ""
          }
        />
        {pageUrl && <meta property="twitter:url" content={pageUrl} />}
        <meta
          property="twitter:title"
          key="twittertitle"
          content={page.head.twitter.title}
        />
        <meta
          property="twitter:description"
          key="twitterdesc"
          content={page.head.twitter.description}
        />
        <meta
          property="twitter:image"
          key="twitterimage"
          content={page?.head?.twitter?.image ? page.head.twitter.image : "/assets/img/thumbnail.png"}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      </Head>
      <ApplicationContextProvider>
        {topbanner && topbanner.show && <TopBanner data={topbanner} />}
        <Component {...pageProps} />
        {!isMobile && <Cursor duration={0.4} size={55} hollowSize={18} />}
        {popup && popup.show == true && <Popup data={popup} />}
      </ApplicationContextProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const router = appContext.router;
  let res = appContext.res;

  try {
    let locale = "en";
    let spl = router.asPath.split("/");
    if (spl && spl.length > 1 && spl[1].trim().length > 0) locale = spl[1];
    if (!locale) {
      throw new Exception("404");
    }

    router.query.locale = locale;

    let tmp = await App.getInitialProps(appContext);
    let popup = await fetchData("/popup", locale);
    let topbanner = await fetchData("/topbanner", locale);
    return {...tmp, popup: popup, topbanner: topbanner };
    //return App.getInitialProps(appContext);
  } catch (e) {
    return {
      props: {
        errorCode: !res || !res.statusCode ? 404 : res.statusCode,
      },
      notFound: true,
    };
  }
  return App.getInitialProps(appContext);
};
export default MyApp;
