import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ArrowRight from "../../public/assets/icons/arrowRight.svg";
import ArrowLeft from "../../public/assets/icons/arrowLeft.svg";
import styles from "./ButtonWithIcon.module.scss";

const ButtonWithIcon = ({
  link,
  fontAwesomeIcon = null,
  customIconComponent,
  showArrow,
  leftArrow,
  outline,
  lightGold = false,
  fill = false,
  empty,
  ...props
}) => (
  <a
    href={link?.url}
    target={link?.target}
    className={`${styles.ButtonWithIcon} ${outline ? styles.outline : styles.full
      } ${lightGold && styles.lightGold} ${fill && styles.fill} ${empty && styles.empty} `}
    {...props}
  >
    {(fontAwesomeIcon || customIconComponent) && (
      <div className={styles.icon}>
        {fontAwesomeIcon && !customIconComponent && (
          <FontAwesomeIcon icon={fontAwesomeIcon} />
        )}
        {!fontAwesomeIcon &&
          customIconComponent &&
          React.createElement(customIconComponent)}
      </div>
    )}
    {leftArrow && (
      <div className={`${styles.icon} ${styles.arrow}`}>
        <ArrowLeft />
      </div>
    )}
    <div className="font-size-1">{link?.title}</div>
    {showArrow && (
      <div className={`${styles.icon} ${styles.arrow}`}>
        <ArrowRight />
      </div>
    )}
  </a>
);

export default ButtonWithIcon;
