import React, { createContext, useState } from "react";

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [visible, setVisibile] = useState("contact");
  const [marketing, setMarketing] = useState(true);
  const [myInfo, setMyInfo] = useState({change:false})
  const [registerData, setRegisterData] = useState({title:"", country:""})

  return (
    <ApplicationContext.Provider
      value={{
        showLoader,
        setShowLoader,
        visible,
        setVisibile,
        marketing,
        setMarketing,
        myInfo,
        setMyInfo,
        registerData,
        setRegisterData
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
