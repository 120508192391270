import axios from "axios";

const _axiosJsonApiInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_DATA_BASEURL}`,
  timeout: 90000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const TOTAL_RETRY_NEXT = 30;
let tentatives = {};
export const fetchData = async (
  url,
  language,
  method = "GET",
  queryParams = null,
  payloadParams = null,
  headers = null,
  siteproperties = false
) => {
  let baseUrl = `${process.env.NEXT_PUBLIC_DATA_BASEURL}`;
  let baseUrlLang = baseUrl;
  if (language) {
    if (!siteproperties) {
      baseUrlLang = baseUrl.replace("wp-json", language + "/wp-json");
    } else {
      baseUrlLang = baseUrl + "/siteproperties/" + language;
    }
  }
  _axiosJsonApiInstance.defaults.baseURL = baseUrlLang;
  if (!tentatives[url]) {
    tentatives[url] = 0;
  }
  try {
    function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    await timeout(25);
    const res = await _axiosJsonApiInstance({
      method: method,
      url: siteproperties ? "" : encodeURIComponent(url).replace(/%2F/g, "/"),
      params: queryParams,
      data: payloadParams,
      ...headers,
    });

    if (res) {
      return res.data;
    }

    return null;
  } catch (error) {
    if (tentatives[url] >= TOTAL_RETRY_NEXT) {
    //   console.log(
    //     "API CALL WITH URL: " +
    //       _axiosJsonApiInstance.defaults.baseURL +
    //       (url.indexOf("filter") != -1
    //         ? url
    //         : encodeURIComponent(url).replace(/%2F/g, "/"))
    //   );
      console.error("There was an error while getting content: " + error);
      return;
    }
    tentatives[url]++;
    return fetchData(
      url,
      language,
      method,
      queryParams,
      payloadParams,
      headers
    );
  }
};
